<template>
	<div class="login-box">
		<div class="login-right">
	       <el-input v-model="phone" maxlength="11" placeholder="请输入手机号" @blur="test" type="text" style="margin-bottom: 40px;" />
		   <el-input v-model="password" placeholder="请输入密码" type="password" show-password />
			<div class="login-button" @click="login">登录</div>
			<div class="login-register" @click="to">注册</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'login',
		data() {
			return {
				phone:"",
				password:""
			}
		},
		methods: {
			tip(type, msg) {
			return this.$message({
				type: type,
				message: msg,
				showClose: true,
				duration: 1000
			})
			},
			to(){
				this.$router.push({
					path:"/register",
					query:{
						province:this.$route.query.province,
						city:this.$route.query.city,
						county:this.$route.query.county,
						town:this.$route.query.town
					}
				})
			},
			test(){
				let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
				if(this.phone != ''){
					if (!reg.test(this.phone)){
					this.tip("error","手机号格式不正确")
				  }
				}
			},
			login(){
				let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
				if (this.phone == '') {
				this.tip("error","请输入手机号")
				return false;
				} else if (!reg.test(this.phone)) {
				this.tip("error","手机号格式不正确")
				return false;
				} else if (this.password == '') {
				this.tip("error","请输入密码")
				return false;
				} else {
				  this.$http.post(this.GLOBAL.new_url + '/trademark_all/service/data/applicantUserLogin', {
				    phoneNumber: this.phone,
				    password: this.password
				  }).then(res => {
				    // console.log(res)
				    if (res.data.code == "200") {
				      this.tip('success', "登录成功");
					  this.$router.push({
						path:'/intellectualProperty',
						query:{
							company:res.data.data.applicantUser
						}
					  })
				    } else {
				      this.$message.error('账号或密码错误')
				    }
				  })
				}
			}
		},
	}
</script>

<style scoped>
	.login-box {
		position: relative;
		height: 100vh;
		width: 100vw;
		background: url('../../../static/images/login-big.png') 100% no-repeat;
  		background-size: cover;
	}
	.login-right{
		width: 767px;
		height: 460px;
		padding-top: 127px;
		position: absolute;
		top:50%;
		right:8%;
		transform: translate(0,-50%);
		text-align: center;
		background: url('../../../static/images/login-input.png') 100% no-repeat;
	}
	:deep(.el-input__inner){
		width: 400px;
		outline: none;
		color: white;
		background: #007E66;
		padding-left: 10px;
		border:1px solid #54FFD6;
		height: 48px;
	}
	:deep(.el-input__suffix){
		margin-right: 185px;
	}
	.login-button{
		width: 400px;
		height: 60px;
		margin: 64px auto 0;
		background: #01C495;
		border-radius: 4px;
		cursor: pointer;
		line-height: 60px;
		text-align: center;
		color: #FFFFFF;
		font-size: 18px;
	}
	.login-register{
		border-radius: 4px;
		width: 120px;
        height: 40px;
		line-height: 40px;
		text-align: center;
		background: #01C495;
		color: #FFFFFF;
		font-size: 18px;
		cursor: pointer;
		margin: 60px 0 0 465px;
	}
</style>
